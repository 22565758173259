import request from '@/util/request';

export function like(copyrightRegistrationId) {
  return request({
    url: `/tran/tranCopyrightCollect/copyright/${copyrightRegistrationId}`,
    method: 'post'
  });
}
export function workLike(copyrightWorkId) {
  return request({
    url: `/tran/tranCopyrightCollect/copyrightWork/${copyrightWorkId}`,
    method: 'post'
  });
}

export function getUserList(params) {
  return request({
    url: `/tran/tranCopyrightCollect/list/user`,
    method: 'get',
    params,
  });
}